<template>
  <v-container fluid>
    <div class="text-h5 mb-8">Cadastros</div>
    <v-data-table :headers="headers" :items="usuariosCompletos" :items-per-page="15" locale="pt"
      class="elevation-1">

      <template v-slot:item.username="{ item }">
        <div class="link" @click="goTo(item.id)">
          {{ item.username }}
        </div>
      </template>
      <template v-slot:[[`item.createdAt`]]="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
      </template>
      <template v-slot:item.role.id="{ item }">
        <div v-if="item.role.type == 'admin_350'">
          <v-icon color="green">mdi-shield-crown</v-icon>
        </div>
        <div v-else>
          --
        </div>
      </template>

    </v-data-table>
    <v-btn style="position: absolute; bottom: 16px" color="accent" @click="$router.go(-1)">voltar</v-btn>
  </v-container>
</template>
<script>
import { isAdmin } from '@/auth'

export default {
  name: "Cadastros",
  data() {
    return {
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "username",
        },
        {
          text: "Denuncias feitas",
          align: "start",
          sortable: true,
          value: "denunciasFeitas",
        },
        {
          text: "Denuncias publicadas",
          align: "start",
          sortable: true,
          value: "denunciasPublicadas",
        },
        {
          text: "Criado em",
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: "Admin",
          align: "start",
          sortable: true,
          value: "role.id",
        },
      ],
      usuariosCompletos: [],
    };
  },
  props: {
    usuarios: Array,
    denuncias: Array,
    userData: Object,
  },
  mounted() {
    this.usuariosCompletos = this.usuarios.map((user) => {
      user.denunciasPublicadas = this.denunciasByUser(user).publishedTrue;
      user.denunciasFeitas =
        this.denunciasByUser(user).publishedTrue +
        this.denunciasByUser(user).publishedFalse;
      return user;
    });
  },
  computed: {
    isAdmin() {
      return isAdmin ? true : false
    }
  },
  methods: {
    goTo(id) {
      this.$router.push({ path: `/app/usuario/${id}` })
    },
    countPublished(array) {
      return array.reduce(
        (acc, cur) => {
          if (cur.publicado) {
            acc.publishedTrue += 1;
          } else {
            acc.publishedFalse += 1;
          }
          return acc;
        },
        { publishedTrue: 0, publishedFalse: 0 }
      );
    },
    denunciasByUser(usuario) {
      let denunciasUsuario = this.denuncias.filter((denuncia) => {
        if (denuncia.users_permissions_user.id == usuario.id) {
          return denuncia;
        }
      });
      let yy = {};
      if (denunciasUsuario) {
        yy = this.countPublished(denunciasUsuario);
      }

      return yy;
    },
  },
};
</script>
<style scoped>
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>