import { render, staticRenderFns } from "./Cadastros.vue?vue&type=template&id=68347cdd&scoped=true"
import script from "./Cadastros.vue?vue&type=script&lang=js"
export * from "./Cadastros.vue?vue&type=script&lang=js"
import style0 from "./Cadastros.vue?vue&type=style&index=0&id=68347cdd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68347cdd",
  null
  
)

export default component.exports